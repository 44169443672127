<template>
  <div>

    <b-container>

      <b-row>
        <b-col class="col-5 mt-3">
          <h5>{{ currentsub.title }}</h5>
        </b-col>
        <b-col class="col-7 mt-3">
          <div class="rtmarkerexpiry" :style="{ backgroundColor: currentsub.color }" v-if="currentsub.status == 'Active'">
            {{ currentsub.status }} {{ currentsub.expirydays }} day<span v-if="currentsub.expirydays !== 1">s</span>
            remaining
          </div>
          <div class="rtmarkerexpiry" :style="{ backgroundColor: currentsub.color }" v-else>
            {{ currentsub.status }}
          </div>
        </b-col>
        <b-col class="col-6 mt-3">
          <h6>Started : {{ currentsub.start }}</h6>
        </b-col>
        <b-col class="col-6 mt-3">
          <h6>Expiry : {{ currentsub.end }}</h6>
        </b-col>

        <b-col class="col-12 mt-3" style="border-top:1px solid rgba(255,255,255,0.7)"></b-col>

      </b-row>

      <b-row v-if="currentsub.renewalsub > 0">
        <b-col class="col-5 mt-3">
          <h5>{{ renewalsub.title }}</h5>
        </b-col>
        <b-col class="col-7 mt-3">
          <div class="rtmarkerexpiry" style="background-color:#3cd2a5">
            {{ renewalsub.statustext }}
          </div>
        </b-col>
        <b-col class="col-6 mt-3">
          <h6>Starts : {{ renewalsub.startdate }}</h6>
        </b-col>
        <b-col class="col-6 mt-3">
          <h6>Expiry : {{ renewalsub.enddate }}</h6>
        </b-col>

        <b-col class="col-12 mt-3">
          <h5>The current subscription will automatically renew on {{ renewalsub.startdate }}</h5>
        </b-col>
        <b-col class="col-12 mt-3" style="border-top:1px solid rgba(255,255,255,0.7)"></b-col>

        <b-col class="col-12 mt-5 mb-3">
          <b-button variant="outline-success" @click.stop="cancelSub()" block>Cancel subscription renewal</b-button>
        </b-col>

      </b-row>

      <b-row v-else>
        <b-col class="col-12 mt-3">
          <h5>Renewal Details</h5>
        </b-col>

        <b-col class="col-12 mt-3" v-if="nostocksubs">
          <div class="rtmarkerexpiry" style="background-color:rgba(255, 96, 96, 0.7)">No dealer stock subscriptions
            available</div>
        </b-col>

        <b-col class="col-12 mt-3">
          <label>Type</label>
          <b-form-select v-model="subsel" :options="subtypes" name="subsel" @change="setSub($event)"
            v-validate="{ min_value: 1 }" :class="{ 'b-form-select': true, 'is-danger': errors.has('subsel') }" />
          <span style="color:red" v-show="errors.has('subsel')">
            ** required</span>
        </b-col>

        <b-col class="col-12 mt-3">
          <label>Reference</label>
          <b-form-input type="search" placeholder="" autocomplete="off" v-model="sub.purchase_order"
            name="sub.purchase_order" v-validate="{ required: true }"
            :class="{ 'darkinput': true, 'is-danger': errors.has('sub.purchase_order') }" class="darkinput" />
          <span style="color:red" v-show="errors.has('sub.purchase_order')">
            {{ errors.first('sub.purchase_order') }}</span>
        </b-col>

        <b-col class="col-12 col-sm-6 mt-3">
          <label>Renewal Date</label>

          <VueCtkDateTimePicker v-model="datesel1" format='DD-MM-YYYY HH:mm' :dark="true"
            :no-value-to-custom-elem="true" :only-date="false">
            <b-form-input type="search" v-model="datesel1" autocomplete="off" placeholder="select" class="darkinput"
              name="datesel1" v-validate="{ required: true }"
              :class="{ 'b-form-select': true, 'is-danger': errors.has('datesel1') }"
              :disabled="subsel == 0 || activatenow == 1" />
          </VueCtkDateTimePicker>

        </b-col>

        <b-col class="col-12 col-sm-6 mt-3">
          <label>Expiration Date</label>
          <VueCtkDateTimePicker v-model="expire" format='DD-MM-YYYY HH:mm' :dark="true" :no-value-to-custom-elem="true"
            :only-date="false">
            <b-form-input type="search" v-model="expire" autocomplete="off" placeholder="automatic" class="darkinput"
              name="expire" v-validate="{ required: true }"
              :class="{ 'b-form-select': true, 'is-danger': errors.has('expire') }" disabled />
          </VueCtkDateTimePicker>

        </b-col>

        <b-col class="col-12 mt-3">
          <b-form-checkbox v-model="activatenow" inline value=1 unchecked-value=0 size="lg" :disabled="subsel == 0" />
          <label>Renew from current date/time</label>
        </b-col>

      </b-row>

      <b-row>

        <b-col class="col-12 mt-3">

          <b-form-checkbox inline v-model="sendsubemail" size="lg" value=1 unchecked-value=0 />
          <label style="margin-right:15px">Send email </label>
        </b-col>

        <b-col class="col-12 mt-3" v-if="sendsubemail == 1">
          <label>Email Address</label>
          <b-form-input type="search" placeholder="" autocomplete="off" v-model="emailto" class="darkinput" />
        </b-col>
      </b-row>


      <b-row class="mt-5 mb-3">

        <b-col class="col-12">
          <b-button variant="outline-success" @click.stop="validateData()" block>Renew subscription</b-button>
        </b-col>
      </b-row>

      <!-- <b-modal ref="cancel-sub"  title="Cancel Subscription Renewal" hide-footer centered content-class="darkmodal">
    <b-row>
        <b-col class="col-12">
            <h5>This will cancel the {{ renewalsub.title }} subscription due to renew on {{ renewalsub.startdate }}</h5>
       </b-col>
       <b-col class="col-12 mt-5">
        <b-button variant="outline-success" @click.stop="cancelRenewal()" block>Cancel renewal</b-button>
       </b-col>      
   </b-row>       
</b-modal> -->

      <b-modal ref="renew-sub" size="lg" title="Renewing Subscription" hide-footer centered content-class="darkmodal">
        <b-row v-for="message in stagemessages " :key="message.id">
          <b-col class="col-12 col-sm-5">
            {{ message.message }}
          </b-col>
          <b-col class="col-12 col-sm-5">
            {{ message.text }}
          </b-col>
          <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="finishRenewal()" block>Finish</b-button>
          </b-col>
        </b-row>

      </b-modal>

      <SendEmail :sendmail="sendmail" :emailid="emailid" :emailto="emailto" :subid="subid" :showdiag="false"
        v-on:emailsent="emailSendStatus" />

      <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />

    </b-container>

  </div>
</template>

<script>
import SendEmail from './SendEmail'
import ErrorLogging from './ErrorLogging'
import axios from 'axios'
import moment from 'moment'
import { Validator } from 'vee-validate'

export default {
  props: ['subid', 'dealerid'],
  name: 'CasterSubscriptionRenew',
  components: {
    SendEmail,
    ErrorLogging
  },
  data() {
    return {
      roleid: 0,
      token: '',
      sub: {},
      stocksub: {},
      currentsub: {},
      renewalsub: {},
      subtypes: [],
      subsel: 0,
      date: 0,
      datenow: '',
      datesel1: '',
      expire: '',
      expirydays: 0,
      updating: false,
      user: {},
      activatenow: 0,
      modaltitle: '',
      sendsubemail: 1,
      sendmail: false,
      sent: 99,
      emailto: '',
      emailid: 0, // Renew Sub
      showdiag: false,
      sending: false,
      errorresponse: {},
      stagemessages: [],
      nostocksubs: false
    };

  },

  mounted() {

    Validator.localize('en', {
      messages: {
        required: '** required.',
      },
    })
    this.roleid = this.$store.getters.roleid
    this.user = this.$store.getters.user
    this.token = this.$store.getters.token
    this.datenow = moment().format('DD-MM-Y HH:mm:ss')
    this.fetchSub()

  },

  watch: {
    activatenow: function () {
      if (this.activatenow == 1) {
        this.datesel1 = this.datenow
        this.setDate()
      } else {
        this.datesel1 = moment(this.sub.enddate2).format('DD-MM-Y HH:mm:ss')
        this.setDate()
      }
      this.sub.activatenow = this.activatenow
    },

    datesel1: function () {
      if (this.subsel != 0) {
        this.setDate()
      }
    },

    showdiag: function () {
      if (this.showdiag) { this.emailto = this.user.email } else this.emailto = this.sub.companyemail
    },
  },


  methods: {


    // Get selected stock sub values when selected
    setSub(event) {
      let data = this.subtypes.filter(sub => sub.value == event)
      this.sub.subtype = data[0].type
      this.sub.subid = data[0].value
      this.stocksub.title = data[0].text
      this.stocksub.days = data[0].days
      this.stocksub.months = data[0].months
      this.stocksub.years = data[0].years
      this.$validator.validate('subsel')
      this.expire = ''
      this.setDate()
    },

    // Calculate expiry dates
    setDate() {
      let datetime = moment(this.datesel1, "DD-MM-YYYY HH:mm")
      let end = ''
      if (this.stocksub.days !== 0) {
        end = moment(datetime).add(this.stocksub.days, 'days')
      }
      if (this.stocksub.months !== 0) {
        end = moment(datetime).add(this.stocksub.months, 'months')
      }
      if (this.stocksub.years !== 0) {
        end = moment(datetime).add(this.stocksub.years, 'years')
      }
      this.expire = moment(end).format('DD-MM-YYYY HH:mm')
      this.sub.substart = moment(datetime).format('DD-MM-YYYY HH:mm')
      this.sub.subend = this.expire // moment(this.sub.end).format('DD-MM-YYYY HH:mm:ss')

      this.$validator.validate('datesel1')
      this.$validator.validate('expire')
    },

    // get current subscription
    fetchSub() {

      axios.get('/api/casterntripsubscriptions', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          id: this.subid
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {

            let sub = response.data.CasterSubs[0]
            let expiry = moment(sub.enddate2, 'YYYY-MM-DD HH:mm')
            let now = moment().format('YYYY-MM-DD HH:mm');

            if (expiry.isBefore(now)) {
              this.datesel1 = this.datenow
              this.activatenow = 1
            } else this.datesel1 = moment(sub.enddate2).format('DD-MM-Y HH:mm')

            this.sub.action = 3             //  Renewal action
            this.sub.id = sub.value         //  current active or expired sub 
            this.sub.rover = sub.rover
            this.sub.username = sub.username
            this.sub.password = sub.password
            this.sub.nosimm = sub.nosimm
            this.sub.countryid = sub.country
            this.sub.resellerid = sub.reseller
            this.sub.dealerid = sub.dealer
            this.sub.companyid = sub.company
            this.sub.user = this.user.id
            this.sub.useremail = sub.companyemail
            this.emailto = sub.companyemail
            this.sub.activatenow = this.activatenow
            this.currentsub.color = sub.color
            this.currentsub.title = sub.subscription
            this.currentsub.expirydays = sub.days
            this.currentsub.status = sub.statustext
            this.currentsub.start = sub.startdate
            this.currentsub.end = sub.enddate
            this.currentsub.renewalsub = sub.renewalsub

            // Get stock subscriptions for this dealer
            this.fetchSubStock()

            if (this.currentsub.renewalsub > 0) {
              this.fetchPendingSub()
            }

          }

        });
    },

    // get pending renewal subscription
    fetchPendingSub() {

      axios.get('/api/castersubscriptionsstock', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          id: this.currentsub.renewalsub,
          short: 1
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else this.renewalsub = response.data.CasterSubs[0]

        });
    },

    fetchSubStock() {
      this.subtypes = []
      axios.get('/api/casterntripsubscriptionsstock2', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          dealer: this.dealerid,
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {

            this.subtypes.push({ value: 0, text: 'Select subscription type' })
            this.subtypes.push(...response.data.CasterSubs) // If new sub just show stock subs

            if (response.data.CasterSubs.length == 0) {
              this.nostocksubs = true
            }
          }
        });

    },

    validateData() {
      this.$validator.validateAll().then(result => {
        if (result) { this.renewSub() }
      });
    },


    renewSub() {

      this.$refs['renew-sub'].show()

      axios({
        method: 'post',
        headers: { "Authorization": "Bearer " + this.token },
        url: '/api/roverrenewsubscription',
        data: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          roverdata: this.sub
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data.message
            this.saving = false
          } else {
            this.stagemessages.push(...response.data.messages)
            if (this.sendsubemail == 1) { this.sendEmail() }
          }

        });
    },

    cancelSub() {
      this.$refs['cancel-sub'].show()
    },

    finishRenewal() {
      this.$emit('update')
    },

    sendEmail() {
      if (this.activatenow == 1) { this.emailid = 5 } else this.emailid = 7
      this.sending = true
      this.sendmail = !this.sendmail // trigger to SendEmail
    },

    emailSendStatus(data) {
      this.stagemessages.push(...data.messages)
      this.sending = false
      this.sent = data.sent
    },


  }
}
</script>