<template>
<div>
<b-row class="mt-1 pb-3" style="border-bottom:1px solid rgba(255,255,255,0.5)">
    <div class="stageselectmain">
        <div class="stageselectbox" style="width:22%"><div class="stageSelect" @click="setPage(1)" v-bind:class="userclass "><b-icon-person /></div>details</div>
        <div class="stageselectline" v-bind:class="line1class"></div>
        <div class="stageselectbox" style="width:22%"><div class="stageSelect" @click="setPage(2)" v-bind:class="roleclass"><b-icon-person-plus /></div>admin role</div>
        <div class="stageselectline" v-bind:class="line2class"></div>
        <div class="stageselectbox" style="width:22%"><div class="stageSelect" @click="setPage(3)" v-bind:class="createclass"><b-icon-check /></div><span v-if="userid == 0">create</span><span v-else>update</span></div>
    
    </div>
                
</b-row>

<b-container class="addrowpage" v-if="page == 1 && display">
    <b-row class="mt-2">
       
        <b-col class="col-12 mt-3">
            <label>First Name</label>
				<b-form-input 
                    type="search" 
                    placeholder="first name" 
                    autocomplete="off"
                    v-model="user.fname"
                    name="user.fname"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('user.fname') }"  
                    class="darkinput"
                    @input = "validateField('user.fname')"
                    />
                    <span style="color:red;">{{ errors.first('user.fname') }}</span>
		</b-col>

		<b-col class="col-12 mt-3">
            <label>Last Name</label>
				<b-form-input 
                    type="search"
                    placeholder="last name"
                    autocomplete="off" 
                    v-model="user.lname" 
                    name="user.lname"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('user.lname') }"
                    class="darkinput"
                    @input = "validateField('user.lname')"  
                    />
                    <span style="color:red;">{{ errors.first('user.lname') }}</span>
		</b-col>

    
        <b-col class="col-12 mt-3">
            <label>Login Email</label>
				<b-form-input 
                    type="search"
                    placeholder="login email"
                    autocomplete="off" 
                    v-model="user.email" 
                    name="user.email"
                    v-validate="'required|customRule:'+emailerror"
                    @input = "validateField('user.email')"
                    :class="{'input': true, 'is-danger': errors.has('user.email') }"
                    class="darkinput"
                    />
                    <span style="color:red;">{{ errors.first('user.email') }}</span>
                    <span v-if="emailerror2" style="color:red;">Invalid email format</span> 
		</b-col>

        <b-col class="col-12 mt-3">
            <label>Phone</label>
				<b-form-input 
                    type="search"
                    placeholder="telephone"
                    autocomplete="off" 
                    v-model="user.phone" 
                    class="darkinput"  
                    />
		</b-col>

        <b-col class="col-12 mt-3">
            <label>Mobile</label>
				<b-form-input 
                    type="search"
                    placeholder="mobile"
                    autocomplete="off" 
                    v-model="user.mobile" 
                    class="darkinput"  
                    />
		</b-col>

        <b-col class="col-12 mt-3">
            <label>Set Password </label>
                <b-input-group>
                <b-form-input 
                    type="search"
                    placeholder="new password"
                    autocomplete="off" 
                    v-model="user.password" 
                    class="darkinput"  
                    />
                <b-input-group-append>
                    <b-button variant="light" @click="createPassword()"><b-icon-arrow-clockwise /></b-button>
                </b-input-group-append>
                </b-input-group>
          
          </b-col>
  
    </b-row>    
   
<b-row class="mt-5">
    <b-col class="col-12">
        <b-button variant="outline-success" block ref="next" size="lg" @click="checkUserDetails()">NEXT</b-button>
     </b-col>  
</b-row>     



</b-container>

<b-container class="addrowpage" v-if="page == 2">


<b-row> 
        <b-col class="col-12 mt-3">
        <label>User Type</label>
        <b-form-select
                    v-model="user.role"
                    :options="roles"
                    @change="setRole($event)"
                    name="user.role"
                    v-validate="{min_value:1}"
					:class="{'darkinput': true, 'is-danger': errors.has('user.role') }" 
                    />
        </b-col>

        <b-col class="col-12 mt-3" v-if="user.role == 5">
        <label>Reseller</label>
        <b-form-select
                    key="dealer-input"
                    v-model="user.business"
                    :options="resellers"
                    name="user.business"
                    v-validate="{min_value:1}"
					:class="{'darkinput': true, 'is-danger': errors.has('user.business') }"
                    @change = "setUserReseller($event)"
                    />
        </b-col> 
      
       <b-col class="col-12 mt-3" v-if="user.role == 10 && roleid < 10">
        <label v-if="user.role != 10">Dealer</label>
        <b-form-select v-if="user.role != 10"
                    key="dealer-input"
                    v-model="user.dealer"
                    :options="dealers"
                    name="user.dealer"
                    v-validate="{min_value:1}"
					:class="{'darkinput': true, 'is-danger': errors.has('user.dealer') }"
                    @change = "setUserDealer($event)"
                    />
        </b-col>

        <b-col class="col-12 mt-3" v-if="user.role == 20 || user.role == 30">
        <label>Company</label>
        <b-form-select
                    key="company-input"
                    v-model="user.company"
                    :options="companies"
                    name="user.company"
                    v-validate="{min_value:1}"
					:class="{'darkinput': true, 'is-danger': errors.has('user.company') }"
                    @change = "setUserCompany($event)"
                    />
        </b-col>

        <!-- <b-col class="col-12 mt-3">
                <b-form-checkbox
                    inline
                    v-model="user.readonly"
                    size="lg"
                    value=1
                    unchecked-value=0
              />
              <label>Read only user </label>
            </b-col>  -->
 
</b-row>  


<b-row class="mt-5">
    <b-col class="col-12">
        <b-button variant="outline-success" block ref="next" size="lg" @click="roleCheck()">NEXT</b-button>
     </b-col>  
</b-row>


</b-container>



<b-container class="addrowpage" v-if="page == 3">

    <b-row class="mt-3">
                <b-col class="col-5 mt-1">Name</b-col>
                <b-col class="col-7 mt-1">{{ user.fname }} {{ user.lname }}</b-col>
                <b-col class="col-5 mt-1">Phone</b-col>
                <b-col class="col-7 mt-1">{{ user.mobile }}</b-col>
                <b-col class="col-5 mt-1">Email</b-col>
                <b-col class="col-7 mt-1">{{ user.email }}</b-col>
                <b-col class="col-5 mt-1">Password</b-col>
                <b-col class="col-7 mt-1">{{ user.password }}</b-col>
                <b-col class="col-5 mt-3">Role</b-col>
                <b-col class="col-7 mt-3">{{ user.roletitle }}</b-col>
                <b-col class="col-5 mt-1" v-if="user.role >= 5">Reseller</b-col>
                <b-col class="col-7 mt-1" v-if="user.role >= 5">{{ user.resellertitle }}</b-col>
                <b-col class="col-5 mt-1" v-if="user.role >= 10">Dealer</b-col>
                <b-col class="col-7 mt-1" v-if="user.role >= 10">{{ user.dealertitle }}</b-col>
                <b-col class="col-5 mt-1" v-if="user.role >= 20">Company</b-col>
                <b-col class="col-7 mt-1" v-if="user.role >= 20">{{ user.companytitle }}</b-col>
              
               
                <b-col class="col-6 offset-5" v-if="user.readonly == 1">Read Only Access</b-col>
    </b-row> 

    <b-row class="mt-3 mb-3">
                <b-col class="col-5 mt-1">
                    <label>Send User Email</label>
                </b-col>    
                <b-col class="col-7 mt-1">
                    <b-form-checkbox
                    inline
                    v-model="showsendemail"
                    size="lg"
                    value=1
                    unchecked-value=0
                    />    
                </b-col>
                <b-col class="col-12 mt-3" v-if="showsendemail == 1">
                    <b-form-input 
                    type="search"
                    placeholder="email address"
                    autocomplete="off" 
                    v-model="emailto" 
                    class="darkinput"  
                    />
                </b-col>
    </b-row>                  

    <b-row class="mt-5">
     <b-col class="col-12">
            <b-button variant="outline-success" block size="lg" @click="createUser()"><span v-if="userid==0">CREATE</span><span v-else>UPDATE</span> USER</b-button>
        </b-col>  
    </b-row>

    <b-modal ref="update-user"  size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
    <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row>

   <b-row>
        <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="finishUser()" block>Finish</b-button>
       </b-col>   
   </b-row>   

</b-modal>    

</b-container>

<SendEmail
        :sendmail="sendmail"
        :emailid="emailid"
        :emailto="emailto"
        :id="addeduser" 
        :showdiag=false
        :pw="user.password"
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
		
</div> 
</template>


<script>
    import { Validator } from 'vee-validate';
    import './customRule';
    import SendEmail from './SendEmail.vue'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['userid','resellerid','dealerid','companyid'],
        name:'CasterUserAdd',
        components:{
            SendEmail,
            ErrorLogging
        },
        data(){
        return {
                token:'',
              
                resellers:[],
                dealers:[],
                companies:[],
                roles:[],
                stagemessages:[],
                dealer:{},
                user:{
                    value:0,
                    fname:'',
                    lname:'',
                    password:'',
                    business:0,
                    dealer:0,
                    company:0,
                    role:0,
                    email:'',
                    phone:'',
                    mobile:'',
                    resellertitle:'',
                    dealertitle:'',
                    companytitle:''
                },
                reseller:0,
                role:0,
                roleid:0,
                page:1,
                pagesel:1,
                addeduser:0,
                userdetail:false,
                roledetail:false,
                userclass:'stageactive',
                roleclass:'',
                createclass:'',
                line1class:'stageselectline',
                line2class:'',
                emailerror:false,
                emailerror2:false,
                showsendemail:1,
                sendmail:false,
                emailto:'',
                emailid:10, // Dealer 31 Company 21 User details email
                display:false,
                modaltitle:'',
                errorresponse:{},
                };
                
                },

        created(){
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            
        }, 
        
        mounted(){

            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   

            this.fetchResellers()
            this.fetchDealers()
            this.fetchRoles()
            
            if(this.userid == 0){ // New User
                this.createPassword()
                this.userclass = 'stagecomp' 
            } else { // Edit User
                this.userclass = 'stagecomp'
                this.roleclass = 'stagecomp'
                this.createclass = 'stagecomp'
                this.line1class = 'stageselectlinecomp'
                this.line2class = 'stageselectlinecomp'
                this.fetchUser()
            }
            this.display = true
          },
          
          methods: {

            roleCheck(){

               // if((this.role == 10 && this.user.dealer !== 0) || (this.role == 20 && this.user.company !== 0) || this.role == 5 || this.role == 1)
               // { FIXME
                        this.line2class = 'stageselectlinecomp'
                        this.roleclass = 'stagecomp'
                        this.createclass = 'stagecomp'
                        this.page = 3
               // }
             
            },

            setRole(roleid){
                this.role = roleid
                switch(roleid){
                    case 1: // sys admin
                    this.user.business = 0    
                    this.user.dealer = 0
                    this.user.company = 0
                    break    
                    case 5: // reseller admin
                    this.user.dealer = 0
                    this.user.company = 0
                    break    
                    case 10: // dealer admin
                    this.user.company = 0    
                    this.setUserDealer(this.$store.getters.user.dealer);
                    break;
                    case 20: // company admin
                    case 30: // company user   
                    this.fetchCompanies()
                    break;            
                }
               
                let role = this.roles.filter(role => role.value == this.role)
                this.user.roletitle = role[0]['text']
            },

            setUserReseller(id){
               this.user.business = id
               let reseller = this.resellers.filter(reseller => reseller.value == this.user.business)
               this.user.resellertitle = reseller[0]['text']
           }, 

           setUserDealer(id){
               this.user.dealer = id
               let dealer = this.dealers.filter(dealer => dealer.value == id)
               this.user.dealertitle = dealer[0]['text']
               this.user.business = dealer[0]['reseller']
               let reseller = this.resellers.filter(reseller => reseller.value == this.user.business)
               this.user.resellertitle = reseller[0]['text']
           },

           setUserCompany(id){
               this.user.company = id
               let company = this.companies.filter(company => company.value == id)
               this.user.companytitle = company[0]['text']
               this.user.business = company[0]['reseller']
               this.user.dealer = company[0]['dealer']
               let reseller = this.resellers.filter(reseller => reseller.value == this.user.business)
               this.user.resellertitle = reseller[0]['text']
               let dealer = this.dealers.filter(dealer => dealer.value == this.user.dealer)
               this.user.dealertitle = dealer[0]['text']
           },

           checkUserDetails(){
                this.$validator.validateAll().then(result => {
                    if(result){        

                    if (!this.isValidEmail(this.user.email)) {
                    this.emailerror2 = true
                    return
                    } else this.emailerror2 = false  

                    this.emailto = this.user.email
                    this.roleclass = 'stagecomp'
                    this.line1class = 'stageselectlinecomp'
                    this.page = 2
                    }
                })    
            },

            isValidEmail(email) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
            }, 

           validateField(field){
                setTimeout(() => this.validateDebounced(field), 300);
            },

            validateDebounced(field){
                this.$validator.validate(field)
                if(field == 'user.email'){this.emailCheck()}
            },

           emailCheck(){	
                if(this.user.email !== this.currentemail){

                axios.get('/api/checkuserexists',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        email:this.user.email
                    }
                })
                .then((response) => {

                    if(response.data.code == 999){
                        this.errorresponse = response.data
                    } else {
                
                    this.emailerror2 = false    
                    if(response.data.exists == true){
                        this.emailerror = true
                        } else {
                            this.emailerror = false 
                            }
                    }    
                
                    });

                }    
            },

          
            createPassword(){
                            let pwdChars = "23456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjklmnpqrstuvwxyz"
                            let pwdLen = 8
                            this.user.password = Array(pwdLen).fill(pwdChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('')
            },  

         
            setPage(id){
                
                switch(id){
                    case 1:
                        this.page = 1
                        this.pagesel = 1
                    break
                    case 2:
                        if(this.line1class == 'stageselectlinecomp'){
                            this.page = 2
                            this.pagesel = 2
                            //this.checkUserDetails()
                        }
                    break    
                    case 3:
                         if(this.line2class == 'stageselectlinecomp'){
                            this.page = 3 
                            this.pagesel = 3
                            //this.checkUserDetails()
                         }
                    break     
                }
            },
            
              changeDealer(){
                this.user.company = 0
                this.fetchCompanies()
            },

            fetchCompanies(){

                axios.get('/api/castercompanies',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        business:this.resellerid,
                        dealer:this.dealerid, 
                        list:1
                    }
                })
                .then((response) => {
                        this.companies = []
                        this.companies.push({value:0,text:'Select'})
                        this.companies.push(...response.data.CasterCompanies)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
                },

             fetchDealers(){

				axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellerid,
                        dropdown:1
                     }
                })
				.then((response) => {
                        this.dealers = []
                        this.dealers.push({value:0,text:'Select'})
                        this.dealers.push(...response.data.CasterDealers)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

          
			fetchResellers() {

                axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                        }
                }).then((response) => {
                        this.resellers = []
                        this.resellers.push({value:0,text:'Select'})
                        this.resellers.push(...response.data.CasterBusiness)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

             fetchRoles(){

                axios.get('/api/roles',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.businessid,
                        dealer:this.dealerid,
                        role:this.$store.getters.roleid
                        }
                }).then((response) => {
                        this.roles = []
                        this.roles.push({value:0,text:'Select'})
                        this.roles.push(...response.data.roles)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},  
          
            

            fetchUser(){
                axios.get('/api/casterusers',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:this.userid
                    }
                })
				.then((response) => {
                    if(response.data.code == 999){
                            this.errorresponse = response.data
                        } else {
                        this.user = response.data.users[0]
                        this.currentemail = this.user.email
                        this.role = this.user.role
                        this.display = true
                        this.fetchCompanies()
                        }
                    })
           },

           createUser(){
                if(this.user.value !== 0){this.modaltitle = 'Updating User'} else this.modaltitle = 'Creating User'
                this.$refs['update-user'].show()   
                this.updateUser()
            },

            updateUser(){ 

                axios({
                        method: 'post',    
                        headers: {"Authorization" : "Bearer " + this.token},
                        url: '/api/casteruser',
                        data: {
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            userdata:this.user
                            }
                        })
                    .then((response)=> {

                    if(response.data.code == 999){
                            this.errorresponse = response.data
                            this.stagemessages = response.data.messages
                        } else {
                                this.stagemessages = response.data.messages
                                this.user.value = response.data.user
                                this.addeduser = response.data.user
                                if(this.showsendemail == 1){
                                    
                                    switch(this.user.role){
                                        case 1: // sys admin
                                        this.emailid = 41
                                        break;
                                        case 5: // reseller admin
                                        this.emailid = 40
                                        break;
                                        case 10: // dealer admin
                                        this.emailid = 31
                                        break;
                                        case 20: // company admin
                                        case 30: // company user   
                                        this.emailid = 21 
                                        break;  
                                     }
                                    
                                    this.sendUserEmail()
                                    } 
                                }
                    });    
            },


        
        sendUserEmail(){
                this.sendmail = !this.sendmail // trigger to SendEmail
        },

        emailSendStatus(data){
                this.stagemessages.push(...data.messages)
        }, 
        
        finishUser(){
                    this.$refs['update-user'].hide()
                    this.$emit('updatemodal')
            },

        closemodal(){
                this.$emit('updatemodal')
        },

		}	   
	
	};
</script>